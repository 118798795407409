import React, { Component } from "react";
import { connect } from "react-redux";

import GoogleMapReact from "google-map-react";
import styled from "styled-components";
import { storeMapInformation } from "../../redux/actions";
import Marker from "./marker";
import marker1 from './marker1.png'
import marker2 from './marker2.png'
import truck from './truck.png'

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`;

class MyGoogleMap extends Component {
  state = {
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    geoCoder: null,
    places: [],
    center: [],
    zoom: 0,
    address: "",
    draggable: true,
    lat: null,
    lng: null,
    lat1: null,
    lng1: null,
    currentLat:null,
    currentLng:null,
    distance: 0,
    line: null,
  };

  componentWillMount() {
    if (!this.props.destinationPlaceholder) {
      this.setCurrentLocation();
    }
  }

  _getInitialDistance = async () => {
    this.calculateAndDisplayRoute();
    const distance = await this.calculateDistance();
    if (distance) {
      if (distance.rows[0].elements[0].status === "OK") {
        const value = distance.rows[0].elements[0].distance.text.replace(
          /\D/g,
          ""
        );
        this.setState({ distance: value });
        this.props.onDistanceChange(distance);
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      if (!this.props.location.latitude && this.props.location.address) {
        this._setCoordinate(this.props.location.address, 1);
      } else {
        this.setState({
          lat: this.props.location.latitude,
          lng: this.props.location.longitude,
          address: this.props.location.address,
        });
      }
    }
    if (this.props.location1 !== prevProps.location1) {
      if (!this.props.location1.latitude && this.props.location1.address) {
        this._setCoordinate(this.props.location1.address, 2);
      } else {
        this.setState(
          {
            lat1: this.props.location1.latitude,
            lng1: this.props.location1.longitude,
            address1: this.props.location1.address,
          },
          async () => {
            this._getInitialDistance();
          }
        );
      }
    }
    if (this.props.currentLocation !== prevProps.currentLocation) {
      if (!this.props.currentLocation.latitude && this.props.currentLocation.address) {
        this._setCoordinate(this.props.currentLocation.address, 1);
      } else {
        this.setState({
          currentLat: this.props.currentLocation.latitude,
          currentLng: this.props.currentLocation.longitude,
          currentAddress: this.props.currentLocation.address,
        });
      }
    }
  }

  _setCoordinate = (address, key) => {
    const { mapApi } = this.state;
    const geo = new mapApi.Geocoder();

    geo.geocode({ address: address }, (results, status) => {
      if (status === "OK") {
        let state = "";
        let city = "";
        let district = "";
        for (const component of results[0].address_components) {
          const componentType = component.types[0];

          // eslint-disable-next-line default-case
          switch (componentType) {
            case "administrative_area_level_1":
              state = component.long_name;
              break;
            case "locality":
              city = component.long_name;
              break;
            case "administrative_area_level_2":
              district = component.long_name;
              break;
          }
        }
        let latitude = results[0].geometry.location.lat();
        let longitude = results[0].geometry.location.lng();

        if (key === 1) {
          this.setState({
            lat: latitude,
            lng: longitude,
            address: address,
          });
          if (this.props.onChange) {
            this.props.onChange({
              latitude: latitude,
              longitude: longitude,
              address: address,
              state: state,
              city: city,
              district: district,
            });
          }
        } else {
          this.setState(
            {
              lat1: latitude,
              lng1: longitude,
              address1: address,
            },
            async () => {
              this._getInitialDistance();
            }
          );
          if (this.props.onChangeDestination) {
            this.props.onChangeDestination({
              latitude: latitude,
              longitude: longitude,
              address: address,
              state: state,
              city: city,
              district: district,
            });
          }
        }
      }
    });
  };

  onMarkerInteraction = async (childKey, childProps, mouse) => {
    if (childKey === "1") {
      this.setState({
        draggable: false,
        lat: mouse.lat,
        lng: mouse.lng,
      });
    } else {
      this.setState({
        draggable: false,
        lat1: mouse.lat,
        lng1: mouse.lng,
      });
    }
  };

  onMouseDown = async (childKey, childProps, mouse) => {
    this._generateAddress(childKey);
    if (this.props.onDistanceChange) {
      const distance = await this.calculateDistance();
      if (distance) {
        if (distance.rows[0].elements[0].status === "OK") {
          const value = distance.rows[0].elements[0].distance.text.replace(
            /\D/g,
            ""
          );
          this.setState({ distance: value });
          this.props.onDistanceChange(distance);
        }
      }
      this._fitBound();
    }
  };

  onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    this.setState({ draggable: true });
    this._generateAddress(childKey);
  };

  _onChange = ({ center, zoom }) => {
    this.setState({
      center: center,
      zoom: zoom,
    });
  };

  _onClick = async (value) => {
    if (this.props.destinationPlaceholder) {
      if (!this.state.lat) {
        this.setState({
          lat: value.lat,
          lng: value.lng,
        });
      } else {
        this.setState({
          lat1: value.lat,
          lng1: value.lng,
        });
      }
      if (this.props.onDistanceChange) {
        const distance = await this.calculateDistance(
          value,
          !this.state.lat ? "1" : "2"
        );
        if (distance) {
          this.setState({
            distance: distance.rows[0].elements[0].distance.text.replace(
              /\D/g,
              ""
            ),
          });
          this.props.onDistanceChange(distance);
        }
      }
      this._generateAddress(!this.state.lat ? "1" : "2", {
        lat: value.lat,
        lng: value.lng,
      });
      this.calculateAndDisplayRoute();
    } else {
      this.setState({
        lat: value.lat,
        lng: value.lng,
      });
      if (this.props.onChange) {
        this.props.onChange({ latitude: value.lat, longitude: value.lng });
      }
    }
  };

  apiHasLoaded = (map, maps) => {
    this.setState(
      {
        mapApiLoaded: true,
        mapInstance: map,
        mapApi: maps,
      },
      async () => {
        this._getInitialDistance();
      }
    );

    this.props.storeMapInformation({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
    });
  };

  addPlace = async (place, key) => {
    let state = "";
    let city = "";
    let district = "";
    for (const component of place.address_components) {
      const componentType = component.types[0];
      // eslint-disable-next-line default-case
      switch (componentType) {
        case "administrative_area_level_1":
          state = component.long_name;
          break;
        case "locality":
          city = component.long_name;
          break;
        case "administrative_area_level_2":
          district = component.long_name;
          break;
      }
    }
    if (key === "1") {
      this.setState({
        places: [place],
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        address: place.formatted_address,
      });
      if (this.props.onChange) {
        this.props.onChange({
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
          address: place.formatted_address,
          state: state,

          city: city,
          district: district,
          // distance:distance
        });
      }
    } else {
      this.setState({
        places1: [place],
        lat1: place.geometry.location.lat(),
        lng1: place.geometry.location.lng(),
        address1: place.formatted_address,
      });
      if (this.props.onChangeDestination) {
        this.props.onChangeDestination({
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
          address: place.formatted_address,
          state: state,
          city: city,
          district: district,
        });
      }
    }
    if (this.props.onDistanceChange) {
      const distance = await this.calculateDistance(place, key);
      if (distance) {
        const value = distance.rows[0].elements[0].distance.text.replace(
          /\D/g,
          ""
        );
        this.setState({ distance: value });
        this.props.onDistanceChange(distance);
      }
      this._fitBound();
    }
  };

  calculateAndDisplayRoute = () => {
    if (this.state.mapApi) {
      const directionsRenderer = new this.state.mapApi.DirectionsRenderer();
      const directionsService = new this.state.mapApi.DirectionsService();
      if (this.state.directionsRenderer) {
        this.state.directionsRenderer.setMap(null);
      }
      this.setState({ directionsRenderer: directionsRenderer });
      directionsRenderer.setMap(this.state.mapInstance);
      directionsRenderer.setOptions({ suppressMarkers: true });
      directionsService
        .route({
          origin: { lat: this.state.lat, lng: this.state.lng },
          destination: { lat: this.state.lat1, lng: this.state.lng1 },
          travelMode: this.state.mapApi.TravelMode["DRIVING"],
        })
        .then((response) => {
          directionsRenderer.setDirections(response);
        })
        .catch((e) => console.log("Directions request failed due to ", e));
    }
  };

  calculateDistance = async (place, key) => {
    if (this.state.mapApi) {
      let lat = this.state.lat;
      let lng = this.state.lng;
      let address = this.state.address;
      let lat1 = this.state.lat1;
      let lng1 = this.state.lng1;
      let address1 = this.state.address1;
      if (place && key === "1") {
        lat = place.lat ? place.lat : place.geometry.location.lat();
        lng = place.lng ? place.lng : place.geometry.location.lng();
        // address  = place.formatted_address;
      }
      if (place && key === "2") {
        lat1 = place.lat ? place.lat : place.geometry.location.lat();
        lng1 = place.lng ? place.lng : place.geometry.location.lng();
        // address1  = place.formatted_address;
      }
      let origin1 = new this.state.mapApi.LatLng(lat, lng);
      let origin2 = new this.state.mapApi.LatLng(lat1, lng1);
      // console.log(this.state.lat,this.state.lng,this.state.lat1,this.state.lng1)
      if (lat && lng1) {
        let service = new this.state.mapApi.DistanceMatrixService();
        const distance = await service.getDistanceMatrix(
          {
            origins: [origin1, address],
            destinations: [origin2, address1],
            travelMode: "DRIVING",
            // transitOptions: TransitOptions,
            // drivingOptions: DrivingOptions,
            // unitSystem: UnitSystem,
            avoidHighways: false,
            avoidTolls: false,
          },
          callback
        );

        function callback(response, status) {
          if (status === "OK") {
            return response.rows[0].elements;
          }
        }
        return distance;
      }
    }
  };

  _generateAddress(key, value) {
    const { mapApi } = this.state;
    const geocoder = new mapApi.Geocoder();
    let latVal = value?.lat
      ? value.lat
      : key === "1"
      ? this.state.lat
      : this.state.lat1;
    let lngVal = value?.lng
      ? value.lng
      : key === "1"
      ? this.state.lng
      : this.state.lng1;
    geocoder.geocode(
      {
        location: {
          lat: latVal,
          lng: lngVal,
        },
      },
      (results, status) => {
        if (status === "OK") {
          let state = "";
          let city = "";
          let district = "";
          if (results[0]) {
            for (const component of results[0].address_components) {
              const componentType = component.types[0];
              // eslint-disable-next-line default-case
              switch (componentType) {
                case "administrative_area_level_1":
                  state = component.long_name;
                  break;
                case "locality":
                  city = component.long_name;
                  break;
                case "administrative_area_level_2":
                  district = component.long_name;
                  break;
              }
            }
            this.zoom = 7;
            if (key === "1") {
              this.setState({ address: results[0].formatted_address });
              if (this.props.onChange) {
                this.props.onChange({
                  latitude: this.state.lat,
                  longitude: this.state.lng,
                  address: results[0].formatted_address,
                  state: state,
                  city: city,
                  district: district,
                });
              }
            } else {
              this.setState({ address1: results[0].formatted_address });
              if (this.props.onChangeDestination) {
                this.props.onChangeDestination({
                  latitude: this.state.lat1,
                  longitude: this.state.lng1,
                  address: results[0].formatted_address,
                  state: state,
                  city: city,
                  district: district,
                });
              }
            }
          } else {
            window.alert("No results found");
          }
        }
      }
    );
  }

  _fitBound() {
    const { mapApi, lat, lat1, lng, lng1, mapInstance } = this.state;
    var latlngbounds = new mapApi.LatLngBounds();
    if (lat && lng) {
      latlngbounds.extend({
        lat: lat,
        lng: lng,
      });
    }
    if (lng1 && lat1) {
      latlngbounds.extend({
        lat: lat1,
        lng: lng1,
      });
    }

    mapInstance.setCenter(latlngbounds.getCenter());
    mapInstance.fitBounds(latlngbounds);
    this.calculateAndDisplayRoute();
  }

  // Get Current Location Coordinates
  setCurrentLocation() {
    if ("geolocation" in navigator) {
      let currentPosition = {
        latitude: this.state.lat,
        longitude: this.state.lng,
      };
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({
          center: [position.coords.latitude, position.coords.longitude],
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        currentPosition = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
      });
      if (this.props.onChange) {
        this.props.onChange({ ...currentPosition });
      }
    }
  }

  render() {
    const { mapApiLoaded, mapInstance, mapApi } = this.state;

    return (
      <Wrapper>
        <GoogleMapReact
          defaultZoom={7}
          center={
            this.state.center.length
              ? this.state.center
              : [21.663440263807868, 72.81835706249998]
          }
          zoom={7}
          draggable={true}
          // onChange={this._onChange}
          // onChildMouseDown={this.onMouseDown}
          // onChildMouseUp={this.onMarkerInteractionMouseUp}
          // onChildMouseMove={this.onMarkerInteraction}
          // distanceToMouse={this._distanceToMouse}
          // onClick={
          //   this.props.destinationPlaceholder
          //     ? !this.state.lat || !this.state.lat1
          //       ? this._onClick
          //       : () => {}
          //     : this._onClick
          // }
          bootstrapURLKeys={{
            key: "AIzaSyBIcVV8OT5T1cVeZ16lExutja-yH81c1Vs",
            //  key: 'AIzaSyA5KzseTR2cZPzPA-z5xP0ugnxut84-vBE',
            libraries: ["places", "geometry", "drawing"],
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
        >
          <Marker
            key={1}
            text={this.props.destinationPlaceholder ? "Pickup" : ""}
            image={marker1}
            lat={this.state.lat}
            lng={this.state.lng}
          />
          
          {this.props.destinationPlaceholder && (
            <Marker
              key={2}
              text="Drop point"
              image={marker2}
              lat={this.state.lat1}
              lng={this.state.lng1}
            />
          )}
          <Marker
            key={3}
            text={"Live"}
            image={truck}
            lat={this.state.currentLat}
            lng={this.state.currentLng}
          />
        </GoogleMapReact>
      </Wrapper>
    );
  }
}

const mapDispatchToProps = {
  storeMapInformation,
};

// export default MyGoogleMap
export default connect(null, mapDispatchToProps)(MyGoogleMap);
