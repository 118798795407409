import { Card, Typography } from 'antd';
import React from 'react';

const Home = () => {
  return (
    <Card>
      unknown page
    </Card>
  );
}

export default Home;
