import React from "react";

import MyGoogleMap from "./map";

function map(props) {
  return (
    <div className={`main-wrapper ${props.className}`}>
      <MyGoogleMap {...props} />
    </div>
  );
}

export default map;
