import { LOADMAP } from "../constants/Map";

const initState = {
    map:  null,
    mapApi: null,
    mapApiLoaded:false
}

const map = (state = initState, action) => {
    switch (action.type) {
        case LOADMAP: {
            return {
                ...state,
                 ...action.payload,
            };
        }
        default : return state
    }
}

export default map